// import { Link } from "gatsby";
import React, { Fragment } from "react"
// import { kebabCase } from "lodash";

// render a list of tag badges on the screen

const TagList = ({ tags }) => {
  return (
    <Fragment>
      {tags !== null ? (
        tags.map(tag => (
          // <Link key={tag} to={`/tags/${kebabCase(tag)}`}>
          <div
            key={tag}
            className="cards mx-auto rounded-full px-2 py-1 uppercase bg-secondary text-white "
          >
            <p className="my-auto">{tag}</p>
          </div>
          // </Link>
        ))
      ) : (
        <> </>
      )}
    </Fragment>
  )
}

export default TagList
