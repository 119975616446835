import React from "react"
import { Link } from "gatsby"

import Layout from "../layout/Layout"
import TagList from "../components/blog-posts/TagList"
import useBlogPost from "../utils/useBlogPost"

export default function BlogPost({ pageContext }) {
  const data = useBlogPost()
  const { slug } = pageContext
  const slugString = JSON.stringify(slug)

  const dataFiltered = data.filter(i => JSON.stringify(i.slug) === slugString)

  return (
    <Layout>
      {dataFiltered.map(i => (
        <div className="flex flex-col items-center mx-auto gap-4 py-6 md:py-12 px-4">
          <p className="navbar opacity-50 text-center">
            {i.date.split("T")[0]}
          </p>
          <h1 className="mobile-title lg:desktop-title text-center pt-2">
            {i.title}
          </h1>
          {i.tags !== null && (
            <div className="flex flex-col items-center pb-2">
              <div className="flex items-center justify-center flex-wrap gap-2">
                <TagList tags={i.tags} />
              </div>
            </div>
          )}
          <div
            className="mobile-paragraph lg:dektop-paragraph text-center prose max-w-5xl"
            dangerouslySetInnerHTML={{ __html: i.body.html }}
          />
          <Link
            to="/blog"
            className="cards bg-secondary  text-white rounded-lg px-5 py-3"
          >
            Back to blog
          </Link>
        </div>
      ))}
    </Layout>
  )
}
